<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <tableAdminAddress :dataTable="addresses" />
    </div>
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
    <modalCity :city="city" @change="change" />
  </div>
</template>

<script>
import ModalCity from "@/views/component/Modal/ModalAdminaddress/ModalCity.vue";
import tableAdminAddress from "@/views/component/Table/tableAdminAddress.vue";
export default {
  components: {
    ModalCity,
    tableAdminAddress,
  },
  data() {
    return {
      showPreloader: false,
      addresses: [],
      city: [],
      fields: [
        { key: "number", label: "Номер дома" },
        { key: "streetCode", label: "Улица" },
        { key: "cityCode", label: "Город" },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("city")) {
      this.openFilter();
    } else {
      this.getCity();
    }
  },
  methods: {
    getCity() {
      this.$http.get("admin/city").then((res) => {
        this.city = res.data;
        this.$bvModal.show("modalCity");
      });
    },
    change(event) {
      this.$bvModal.hide("modalCity");
      if (localStorage.getItem("city")) {
        localStorage.removeItem("city");
      }
      localStorage.setItem("city", event);
      this.openFilter();
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let paramData = {
        ...param,
        cityCode: localStorage.getItem("city"),
      };
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http
          .get(`${this.$route.name}`, { params: paramData })
          .then((res) => {
            this.addresses = res.data;
            this.$store.commit("pageData/setdataCount", this.addresses.length);
            this.showPreloader = false;
            this.$store.commit("REFRESH_DATA", false);
          });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    sendToParent(tableData) {
      this.addresses = tableData;
      this.$store.commit("pageData/setdataCount", this.addresses.length);
    },
    refresh() {
      let params = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      params.cityCode = localStorage.getItem("city");
      this.showPreloader = true;
      this.$http.get(`${this.$route.name}`, { params: params }).then((res) => {
        this.addresses = res.data;
        this.showPreloader = false;
        this.$store.commit("pageData/setdataCount", this.addresses.length);
        this.$store.commit("REFRESH_DATA", false);
      });
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData() {
      this.refresh();
    },
  },
  beforeDestroy() {
    localStorage.removeItem("city");
  },
};
</script>

<style lang="scss" scoped></style>
